<template>
    <Header/>
        <Banner name="Signup" message="Verification" />
        <!-- Register & Login Start -->
        <div class="section section-padding">
            <div class="container">

                <!-- Register & Login Wrapper Start -->
                <div class="register-login-wrapper">
                    <div class="row align-items-center">
                        <div class="col-lg-6">

                            <!-- Register & Login Images Start -->
                            <div class="register-login-images">
                                <div class="shape-1">
                                    <img src="@/assets/images/shape/shape-26.png" alt="Shape">
                                </div>


                                <div class="images">
                                    <img src="@/assets/images/register-login.png" alt="Register Login">
                                </div>
                            </div>
                            <!-- Register & Login Images End -->

                        </div>
                        <div class="col-lg-6">

                            <!-- Register & Login Form Start -->
                            <div class="register-login-form">
                                <h3 class="title">OTP <span>Verification</span></h3>

                                <div class="form-wrapper">
                                    <form action="#">
                                        <!-- Single Form Start -->
                                        <div class="single-form">
                                            <input type="text" placeholder="OTP" v-model="details.otp">
                                        </div>
                                        <!-- Single Form Start -->
                                        <div class="single-form">
                                            <button type="button" class="btn btn-primary btn-hover-dark w-100" @click="signup">
                                                {{ loading ? 'Sending...' : 'Submit' }}
                                            </button>
                                        </div>
                                        <!-- Single Form End -->
                                    </form>
                                </div>
                            </div>
                            <!-- Register & Login Form End -->

                        </div>
                    </div>
                </div>
                <!-- Register & Login Wrapper End -->

            </div>
        </div>
        <!-- Register & Login End -->

    <Footer/>
</template>
<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import Banner from '@/components/Banner.vue';
import axios from 'axios';
import Swal from 'sweetalert2';

export default{
    name:"Verification",
    components:{
        Header,Footer,Banner
    },
    data() {
        return {
            details: {},
            loading: false,
        }
    },
    methods: {
        signup() {
            this.loading = true
            this.details.user_id = this.$route.params.id
            axios.post('https://api.bhubcollege.com/api/v1/auth/verify-otp', this.details)
            .then( response => {
                if(response.status) {
                    Swal.fire({
                      title: 'Success',
                      icon: 'success',
                      text: 'Account verification successful'
                    })
                    .then(() => {
                      this.$route.push('/')
                    })
                }
            })
            .catch(err => {
                if(err.response) {
                    let message
                    if(err.response.status == 422 || err.response.status == 200 || err.response.status == 401 || err.response.status == 404) {
                        if(err.response.data.errors) {
                            let errors = err.response.data.errors
                            let errorList = Object.values(errors)
                            errorList.map(msg => {
                                message = msg
                            })
                        }
                        Swal.fire({
                            icon: 'error',
                            text: err.response.data.message || message
                        })
                    }
                }                
            })
            .finally(() => this.loading = false)
        }
    }
}
</script>